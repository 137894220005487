import {ref} from 'vue';
import type {FilterOptions} from '~/lib/filter/filterTypes';
import type {RiskOverviewItemForFilters} from '~/lib/types/riskTypes';
import type {SortedStates, SortFn, Sorting} from '~/composables/useSorting';
import {sortLevel} from '~/lib/riskHelper';

const filterOptions = ref<FilterOptions<RiskOverviewItemForFilters>>({
	search: {
		value: '',
		keys: ['title', 'type']
	},
	filters: [
		{
			label: 'Risicoklasse',
			type: 'checkbox',
			key: 'filterRiskLevel',
			values: [],
			options: [
				{
					value: 'HIGH',
					label: 'Hoog risicoklasse'
				},
				{
					value: 'MEDIUM',
					label: 'Middel risicoklasse'
				},
				{
					value: 'LOW',
					label: 'Laag risicoklasse'
				}
			]
		},
		{
			label: 'Aangepaste risicoklasse',
			type: 'select',
			key: 'filterHasCustomRiskLevel',
			value: '',
			options: [
				{
					value: 'NO_CUSTOM_RISK_LEVEL',
					label: 'Geen aangepaste risicoklasse'
				},
				{
					value: 'HAS_CUSTOM_RISK_LEVEL',
					label: 'Aangepaste risicoklasse'
				}
			]
		},
		{
			label: 'Acties',
			type: 'select',
			key: 'filterAction',
			value: '',
			options: [
				{
					value: 'HAS_ACTIONS',
					label: 'Met acties'
				},
				{
					value: 'NO_ACTIONS',
					label: 'Zonder acties'
				}
			]
		},
		{
			label: 'Grondoorzaak',
			type: 'select',
			key: 'filterRootCauses',
			value: '',
			options: [
				{
					value: 'NO_ROOT_CAUSES',
					label: 'Zonder grondoorzaken'
				},
				{
					value: 'HAS_ROOT_CAUSES',
					label: 'Met grondoorzaken'
				}
			]
		}
	]
});

export const useRisksOverview = () => {
	const headerConfig = [
		{
			text: 'Risico',
			class: 'title',
			key: 'title',
			sortable: true
		},
		{
			text: 'Risico type',
			key: 'type',
			class: 'type',
			sortable: true
		},
		{
			text: 'Aantal acties',
			class: 'status',
			key: 'numberOfActions',
			sortable: true
		}
	];

	const defaultSorting = {
		key: 'level',
		value: 'desc'
	} satisfies Sorting;

	const customSortingFunctions = {
		'level': (a: RiskOverviewItemForFilters, b: RiskOverviewItemForFilters, sort: SortedStates) => {
			return sortLevel(a, b, sort);
		}
	} satisfies Record<string, SortFn<RiskOverviewItemForFilters>>;

	return {
		headerConfig,
		customSortingFunctions,
		defaultSorting,
		filterOptions
	};
};

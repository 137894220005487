<template>
	<div class="risk-overview">
		<OverviewFilter
				:options="filterOptions"
				search-placeholder="Zoek op risico"
				@show-filter-dialog="$emit('open-filter')"
		/>
		<OverviewSummary
				v-if="showCount"
				:total="filteredItems.length"
				type="risico's"
		>
			<template v-if="!readOnly" #buttons>
				<BaseButton
						v-if="riskType === 'actueel'"
						btn-class="secondary"
						data-test-id="add-custom-risk"
						text="Eigen risico toevoegen"
						@click="$emit('add-risk')"
				/>
			</template>
		</OverviewSummary>

		<OverviewHeader
				v-if="showHeaders && risks.length > 0"
				:headers="headerConfig"
				:sorting="sorting"
				@sort="updateSorting"
		/>

		<div class="risks">
			<RiskOverviewElement
					v-for="item in filteredItems"
					:key="item.id"
					:risk="item"
			/>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import {computed, type PropType, type ComputedRef, toRaw} from 'vue';
	import type {RiskOverviewItem, RiskOverviewItemForFilters} from '~/lib/types/riskTypes';
	import RiskOverviewElement from '~/components/risks/RiskOverview/RiskOverviewElement.vue';
	import OverviewHeader from '~/components/generic/overview/OverviewHeader.vue';
	import OverviewSummary from '~/components/generic/overview/OverviewSummary.vue';
	import {useSorting} from '~/composables/useSorting';
	import {getRiskLevel} from '~/lib/riskHelper';
	import BaseButton from '~/components/generic/buttons/BaseButton.vue';
	import OverviewFilter from '~/components/generic/overview/filter/OverviewFilter.vue';
	import {useFilter} from '~/composables/useFilter';
	import {useRisksOverview} from '~/composables/useRisksOverview';

	const props = defineProps({
		risks: {
			type: Array as PropType<RiskOverviewItem[]>,
			required: true
		},
		riskType: {
			type: String as PropType<'actueel' | 'overig'>,
			default: 'actueel'
		},
		showCount: {
			type: Boolean,
			default: true
		},
		showHeaders: {
			type: Boolean,
			default: true
		},
		readOnly: {
			type: Boolean,
			default: false
		}
	});

	defineEmits(['add-risk', 'open-filter']);

	const {filterOptions, defaultSorting, customSortingFunctions, headerConfig} = useRisksOverview();

	const risksComputed: ComputedRef<RiskOverviewItemForFilters[]> = computed(() => {
		return props.risks.map((risk: RiskOverviewItem) => {
			const newRisk: RiskOverviewItemForFilters = {
				id: risk.id,
				numberOfActions: risk.numberOfActions,
				riskRootCauses: toRaw(risk.riskRootCauses),
				title: risk.title,
				type: risk.type,
				level: risk.level,
				levelDeviationText: risk.levelDeviation?.level,
				filterAction: risk.numberOfActions > 0 ? 'HAS_ACTIONS' : 'NO_ACTIONS',
				filterRootCauses: (risk.riskRootCauses ?? []).length > 0 ? 'HAS_ROOT_CAUSES' : 'NO_ROOT_CAUSES',
				filterRiskLevel: getRiskLevel(risk),
				filterHasCustomRiskLevel: risk.levelDeviation?.level ? 'HAS_CUSTOM_RISK_LEVEL' : 'NO_CUSTOM_RISK_LEVEL'
			};
			return newRisk;
		});
	});

	const {
		sortedItems,
		updateSorting,
		sorting
	} = useSorting<RiskOverviewItemForFilters>(risksComputed, defaultSorting, [
		'level',
		'type',
		'numberOfActions'
	], customSortingFunctions);

	const {filteredItems} = useFilter<RiskOverviewItemForFilters>(sortedItems, filterOptions);

</script>

<style scoped>
	.risk-overview {
		background: #ffffff;
		--risk-overview-grid-desktop: "title type status cta" auto / 1fr 220px 136px 78px;
		--risk-overview-grid-mobile: "title title" auto "type status" auto "cta cta" / 1fr 1fr;

		--risk-overview-grid: var(--risk-overview-grid-mobile);
		--overview-header-grid: var(--risk-overview-grid);
		--overview-header-display: none;

		--cell-padding-y: 20px;
		--cell-padding-x: 10px;

		--risk-overview-item-gap: var(--spacing-2);

		@media screen and (min-width: 870px) {
			--risk-overview-grid: var(--risk-overview-grid-desktop);
			--overview-header-display: grid;
		}

		@media screen and (min-width: 1040px) {
			--cell-padding-y: 20px;
			--cell-padding-x: 20px;
		}
	}

	.risks {
		background-color: var(--background-color-main);
		gap: var(--risk-overview-item-gap);
		display: flex;
		flex-direction: column;
		padding: 0;

		@media screen and (max-width: 869px) {
			padding-top: var(--risk-overview-item-gap);
		}
	}
</style>
